var exports = {};

/*
 * Widget types.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WidgetTypesEnum = void 0;
var WidgetTypesEnum;

(function (WidgetTypesEnum) {
  WidgetTypesEnum["BLOCK"] = "BLOCK";
  WidgetTypesEnum["LAYER"] = "LAYER";
})(WidgetTypesEnum = exports.WidgetTypesEnum || (exports.WidgetTypesEnum = {}));

export default exports;