import _entryPointTypesV from "./entryPointTypesV2";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApplicationAuthenticationStatusesEnum = void 0;
var entryPointTypesV2_1 = _entryPointTypesV;
var ApplicationAuthenticationStatusesEnum;

(function (ApplicationAuthenticationStatusesEnum) {
  ApplicationAuthenticationStatusesEnum["AUTHORIZED"] = "authorized";
})(ApplicationAuthenticationStatusesEnum = exports.ApplicationAuthenticationStatusesEnum || (exports.ApplicationAuthenticationStatusesEnum = {}));

export default exports;