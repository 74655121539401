import _asyncTypesV from "./internal/asyncTypesV2";
import _attachmentTypesV from "./internal/attachmentTypesV2";
import _channelTypesV from "./internal/channelTypesV2";
import _commentTypesV from "./internal/commentTypesV2";
import _contactTypesV from "./internal/contactTypesV2";
import _contextTypesV from "./internal/contextTypesV2";
import _conversationTypesV from "./internal/conversationTypesV2";
import _draftTypesV from "./internal/draftTypesV2";
import _entryPointTypesV from "./internal/entryPointTypesV2";
import _errorTypesV from "./internal/errorTypesV2";
import _httpTypesV from "./internal/httpTypesV2";
import _idTypesV from "./internal/idTypesV2";
import _inboxTypesV from "./internal/inboxTypesV2";
import _linkTypesV from "./internal/linkTypesV2";
import _messageTypesV from "./internal/messageTypesV2";
import _paginationTypesV from "./internal/paginationTypesV2";
import _recipientTypesV from "./internal/recipientTypesV2";
import _tagTypesV from "./internal/tagTypesV2";
import _teammateTypesV from "./internal/teammateTypesV2";
import _topicTypesV from "./internal/topicTypesV2";
import _widgetTypesV from "./internal/widgetTypesV2";
var exports = {};

var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __exportStar = exports && exports.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBridge = void 0;

function getBridge() {
  // eslint-disable-next-line no-undef
  return window.buildBridge("2.0.0");
}

exports.getBridge = getBridge;
/*
 * Re-export types.
 */

__exportStar(_asyncTypesV, exports);

__exportStar(_attachmentTypesV, exports);

__exportStar(_channelTypesV, exports);

__exportStar(_commentTypesV, exports);

__exportStar(_contactTypesV, exports);

__exportStar(_contextTypesV, exports);

__exportStar(_conversationTypesV, exports);

__exportStar(_draftTypesV, exports);

__exportStar(_entryPointTypesV, exports);

__exportStar(_errorTypesV, exports);

__exportStar(_httpTypesV, exports);

__exportStar(_idTypesV, exports);

__exportStar(_inboxTypesV, exports);

__exportStar(_linkTypesV, exports);

__exportStar(_messageTypesV, exports);

__exportStar(_paginationTypesV, exports);

__exportStar(_recipientTypesV, exports);

__exportStar(_tagTypesV, exports);

__exportStar(_teammateTypesV, exports);

__exportStar(_topicTypesV, exports);

__exportStar(_widgetTypesV, exports);

export default exports;
export const __esModule = exports.__esModule,
      buildApplicationCancelTokenSource = exports.buildApplicationCancelTokenSource,
      ApplicationCancelError = exports.ApplicationCancelError,
      ApplicationAuthenticationStatusesEnum = exports.ApplicationAuthenticationStatusesEnum,
      EntryPointTypesEnum = exports.EntryPointTypesEnum,
      ApplicationDefaultError = exports.ApplicationDefaultError,
      ApplicationError = exports.ApplicationError,
      HttpVerbsEnum = exports.HttpVerbsEnum,
      WidgetTypesEnum = exports.WidgetTypesEnum;
const _getBridge = exports.getBridge;
export { _getBridge as getBridge };